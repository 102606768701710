import { forwardRef } from 'react';

import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg
          width="96"
          height="40"
          viewBox="0 0 96 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="20" fill="#FFFFFF" />
          <path
            d="M8 17.2308H9.53846L16.9231 32L20.3077 25.6213M18.7692 23.0769L15.6923 16.6154L20.3077 8L21.5385 10.3795M18.7692 23.0769L20.3077 20.2051M18.7692 23.0769L16.6154 27.3846L11.3846 16.9231M20.3077 12.9231L22.4615 16.6154L20.3077 20.2051M20.3077 12.9231L18.1538 16.6154L20.3077 20.2051M20.3077 12.9231L21.5385 10.3795M21.5385 10.3795L24.9231 16.9231L21.6578 23.0769M20.3077 25.6213L23.3846 32L31.3846 17.2308H32.6154M20.3077 25.6213L21.6578 23.0769M21.6578 23.0769L23.3846 27.0769L28.9231 16.9231"
            stroke="#002EA7"
          />
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;