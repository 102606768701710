import { makeStyles } from '@material-ui/styles';

import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';

// Import SvgColor component

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children, image, title }: Props) {
  const useStyles = makeStyles({
    image: {
      flexGrow: 1,
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
  });

  const classes = useStyles();

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        pb: { xs: 30, md: 20 },
      }}
    >
      <Stack
        sx={{
          display: { xs: 'flex', lg: 'none' },
          alignItems: 'center',
          mb: 10,
        }}
      >
        <img src="/logo/group.svg" alt="logo" />
      </Stack>

      {children}
    </Stack>
  );

  const renderSection = <Stack className={classes.image} />;

  return (
    <Grid minHeight="100vh" container columns={12}>
      <Grid item xs={0} sm={0} lg={6}>
        {renderSection}
      </Grid>
      <Grid justifyContent="center" display="flex" alignItems="center" item xs={12} sm={12} lg={6}>
        {renderContent}
      </Grid>
    </Grid>
  );
}
