import { CustomerBasicFormValues } from 'src/interfaces';

import API from '..';
import { kyc, pennyDrop, transaction } from '../entpoints';

export const getTransactionCounts = async () => {
  try {
    const resp = await API.get(transaction.transactionsCount);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getKycCounts = async () => {
  try {
    const resp = await API.get(kyc.kycCount);

    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// get transaction walkins
export const getTransactions = async (
  status: string | undefined,
  pageSize: number = 10,
  page: number = 1,
  startDate: string = '',
  endDate: string = '',
  searchText: string = '',
  transactionType: string = ''
) => {
  try {
    const resp = await API.get(
      `${transaction.index}?status=${status}&page_size=${pageSize}&page_number=${page}&start_date=${startDate}&end_date=${endDate}&search_text=${searchText}&transaction_type=${transactionType}`
    );
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getWalkins = async () => {
  try {
    const resp = await API.get(transaction.getWalkins);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// get pending estimations
export const getPendingEstimations = async () => {
  try {
    const resp = await API.get(transaction.pendingEstimations);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateTransactionCustomerDetails = async (
  id: string,
  data: CustomerBasicFormValues
) => {
  try {
    const resp = await API.patch(`${transaction.index}/${id}/basic_details`, {
      ...data,
      location: data.location?.label,
    });
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getTransactionDetailsById = async (transactionId: string) => {
  try {
    const resp = await API.get(`${transaction.getTransactionById}/${transactionId}`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// Mark customer as walk out
export const markCustomerAsWalkout = async (transactionId: string, reason: string) => {
  try {
    const walkoutPayload = {
      status: 'WALKOUT',
      walkout_reason: reason,
    };
    const resp = await API.patch(`${transaction.index}/${transactionId}`, walkoutPayload);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getPennyDropTransaction = async (payload: {
  transaction_id: string;
  payment_type: string;
}) => {
  try {
    const resp = await API.post(`${pennyDrop.getPayment}`, payload);
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};
export const initiatePennyDrop = async (payload: { transaction_id: string; otp: any }) => {
  try {
    const resp = await API.post(`${pennyDrop.getOtp}`, payload);
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const reinitiatePennyDropOTP = async (transactionId: string) => {
  try {
    const resp = await API.post(`${pennyDrop.resentOtp}`, { transaction_id: transactionId });
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};
export const updateBankDetails = async (kycId: string, formData: any) => {
  try {
    const resp = await API.patch(`${pennyDrop.updateBank}/${kycId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

// Mark customer as walk out
export const proceedToQuotations = async (transactionId: string) => {
  try {
    const payload = {
      status: 'QUOTATION_PENDING',
    };
    const resp = await API.patch(`${transaction.index}/${transactionId}`, payload);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};


