import { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import App from './app';

// Get the DSN & ENV from environment variables
const sentryDsn = import.meta.env.VITE_SENTRY_URL;
const env = import.meta.env.VITE_ENVIRONMENT;

// Initialize Sentry
Sentry.init({
  dsn: sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  tracePropagationTargets: ['https://beta.whitegold.online/', 'https://crm.whitegold.online/'],
  replaysSessionSampleRate: 0.1, // Set sample rate at 10%
  replaysOnErrorSampleRate: 1.0, // Capture 100% of sessions where errors occur
  environment: env,
});

// Render the application
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
