import React from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { salesStatusHandlers } from 'src/utils/alert-status-handlers';

import { Estimation } from 'src/interfaces';
import { AppDispatch } from 'src/store/store';
import { salesApproval } from 'src/apis/estimations';
import { setAlert } from 'src/store/slices/alerts-slice';

import CustomModal from 'src/components/modal';
import { RHFTextField } from 'src/components/hook-form';

function ApprovalModal({
  open,
  onClose,
  originalGoldRate,
}: {
  open: boolean;
  onClose: () => void;
  originalGoldRate: any;
}) {
  const { handleSubmit, setValue } = useFormContext();
  const dispatch = useDispatch<AppDispatch>();
  const params = new URLSearchParams(window.location.search);
  const { estimation_id } = useParams();
  const router = useRouter();

  const handleApproval = async (e: any) => {
    try {
      const transactionId: any = params.get('trans_id');
      const { margin_24k, margin_22k, rate_17k_21k, rate_14k_17k } = originalGoldRate;
      e.is_rates_updated = false;
      let updated = false;
      if (margin_24k !== (e.margin_24k ?? 0) * 1) {
        e.updated_margin_24k = e.margin_24k;
        updated = true;
      }

      if (margin_22k !== (e.margin_22k ?? 0) * 1) {
        e.updated_margin_22k = e.margin_22k;
        updated = true;
      }

      if (rate_17k_21k !== (e.rate_17k_21k ?? 0) * 1) {
        e.updated_rate_17k_21k = e.rate_17k_21k;
        updated = true;
      }

      if (rate_14k_17k !== (e.rate_14k_17k ?? 0) * 1) {
        e.updated_rate_14k_17k = e.rate_14k_17k;
        updated = true;
      }
      e.is_rates_updated = updated;
      const response = await salesApproval(e, transactionId, false, estimation_id || '');
      showAlerts(response.estimation);
      handleCancel();
      router.back();
    } catch (error) {
      console.log(error);
    }
  };

  // alert handlers
  const showAlerts = (estimation: Estimation) => {
    const { title, type, message, status } = salesStatusHandlers(estimation);
    dispatch(setAlert({ title, type, message, status }));
  };

  const handleCancel = () => {
    onClose();
    setValue('sales_remark', '');
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title="Approve Estimation"
      onSubmit={handleSubmit(handleApproval)}
      buttons={
        <Stack direction="row" spacing={2}>
          <LoadingButton variant="outlined" onClick={handleCancel}>
            Cancel
          </LoadingButton>
          <LoadingButton type="submit" variant="contained">
            Approve
          </LoadingButton>
        </Stack>
      }
    >
      <Box py={2}>
        <RHFTextField rows={3} name="sales_remark" multiline label="Remarks" />
      </Box>
    </CustomModal>
  );
}

export default ApprovalModal;
