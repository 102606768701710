import { toast } from 'react-hot-toast';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import formatINR from 'src/utils/format-inr';
import { salesStatusHandlers } from 'src/utils/alert-status-handlers';

import { Estimation } from 'src/interfaces';
import { AppDispatch } from 'src/store/store';
import { requestSalesHead } from 'src/apis/estimations';
import { setAlert } from 'src/store/slices/alerts-slice';
import { updateCurrentTransactions } from 'src/store/slices/transaction-slice';

import CustomModal from 'src/components/modal';

function SalesHeadApprovalModal({
  open,
  onClose,
  originalGoldRate,
}: {
  open: boolean;
  onClose: () => void;
  originalGoldRate: any;
}) {
  // useFormContext to access the form methods
  const { handleSubmit, control } = useFormContext();
  // useFormContext to access the form methods
  const router = useRouter();

  // store
  const dispatch = useDispatch<AppDispatch>();

  const { estimation_id } = useParams();
  const params = new URLSearchParams(window.location.search);

  // state
  const [loading, setLoading] = useState(false);
  const [changeMessages, setChangeMessages] = useState<JSX.Element[]>([]);

  // Watch for changes in specific fields using useWatch
  const watchedValues = useWatch({
    control,
    name: ['margin_24k', 'margin_22k', 'rate_17k_21k', 'rate_14k_17k'], // Fields to watch
  });

  const classes = useStyles();

  useEffect(() => {
    const { margin_24k, margin_22k, rate_17k_21k, rate_14k_17k } = originalGoldRate;
    const newMessages: JSX.Element[] = [];

    // Utility function to check if the difference is 25 or more
    const isBold = (oldValue: number, newValue: number) => Math.abs(oldValue - newValue) >= 25;

    if (margin_24k !== (watchedValues[0] ?? 0) * 1) {
      newMessages.push(
        <div className={classes.messageStyle}>
          <span className={classes.labelStyle}>24k Gold rate</span> :
          <span
            className={`${classes.valueStyle} ${isBold(margin_24k, watchedValues[0]) ? classes.bold : ''}`}
          >
            Rs. {formatINR(watchedValues[0])}
          </span>
        </div>
      );
    }

    if (margin_22k !== (watchedValues[1] ?? 0) * 1) {
      newMessages.push(
        <div className={classes.messageStyle}>
          <span className={classes.labelStyle}>22k Gold rate</span> :
          <span
            className={`${classes.valueStyle} ${isBold(margin_22k, watchedValues[1]) ? classes.bold : ''}`}
          >
            Rs. {formatINR(watchedValues[1])}
          </span>
        </div>
      );
    }

    if (rate_17k_21k !== (watchedValues[2] ?? 0) * 1) {
      newMessages.push(
        <div className={classes.messageStyle}>
          <span className={classes.labelStyle}>21k-17k Gold rate</span> :
          <span
            className={`${classes.valueStyle} ${isBold(rate_17k_21k, watchedValues[2]) ? classes.bold : ''}`}
          >
            Rs. {formatINR(watchedValues[2])}
          </span>
        </div>
      );
    }

    if (rate_14k_17k !== (watchedValues[3] ?? 0) * 1) {
      newMessages.push(
        <div className={classes.messageStyle}>
          <span className={classes.labelStyle}>17k-14k Gold rate</span> :
          <span
            className={`${classes.valueStyle} ${isBold(rate_14k_17k, watchedValues[3]) ? classes.bold : ''}`}
          >
            Rs. {formatINR(watchedValues[3])}
          </span>
        </div>
      );
    }

    setChangeMessages(newMessages);
  }, [watchedValues, originalGoldRate ]);

  const handleApproval = async (e: any) => {
    try {
      setLoading(true);
      const { margin_24k, margin_22k, rate_17k_21k, rate_14k_17k } = originalGoldRate;
      let updated = false;

      // Check if margin_24k is updated
      if (margin_24k !== (e.margin_24k ?? 0) * 1) {
        e.updated_margin_24k = e.margin_24k;
        updated = true;
      }

      // Check if margin_22k is updated
      if (margin_22k !== (e.margin_22k ?? 0) * 1) {
        e.updated_margin_22k = e.margin_22k;
        updated = true;
      }

      // Check if rate_17k_21k is updated
      if (rate_17k_21k !== (e.rate_17k_21k ?? 0) * 1) {
        e.updated_rate_17k_21k = e.rate_17k_21k;
        updated = true;
      }

      // Check if rate_14k_17k is updated
      if (rate_14k_17k !== (e.rate_14k_17k ?? 0) * 1) {
        e.updated_rate_14k_17k = e.rate_14k_17k;
        updated = true;
      }

      // Add a flag indicating if any rates were updated
      e.is_rates_updated = updated;

      const transactionId: any = params.get('trans_id');
      const response = await requestSalesHead(e, transactionId, estimation_id || '');

      // Dispatch the updated transaction details
      dispatch(updateCurrentTransactions(response));

      // Show alerts and navigate back after submission
      showAlerts(response.estimation);
      onClose();
      router.back();
      toast.success(
        'Your changes have been successfully forwarded to the Sales Head for gold rate approval.'
      );
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };


  const handleCancel = () => {
    onClose();
  };

  const showAlerts = (estimation: Estimation) => {
    const { title, type, message, status } = salesStatusHandlers(estimation);
    dispatch(setAlert({ title, type, message, status }));
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title="Approval Required"
      onSubmit={handleSubmit(handleApproval)}
      buttons={
        <Stack direction="row" spacing={2}>
          <LoadingButton variant="outlined" onClick={handleCancel}>
            Cancel
          </LoadingButton>
          <LoadingButton loading={loading} type="submit" variant="contained">
            Forward to Sales Head
          </LoadingButton>
        </Stack>
      }
    >
      <Typography mb={4} fontSize={16}>
        {changeMessages.length > 0 ? (
          <>
            <div style={{ marginBottom: '16px' }}>
              New gold rates have been set. Please get approval from Sales Head.
            </div>
            {changeMessages.map((message, index) => (
              <div key={index}>{message}</div>
            ))}
          </>
        ) : (
          'No gold rate changes detected.'
        )}
      </Typography>
    </CustomModal>
  );
}

const useStyles = makeStyles(() => ({
  messageStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  labelStyle: {
    minWidth: '140px', // Adjust this width to align the labels
    fontWeight: 'bold',
  },
  valueStyle: {
    marginLeft: '10px', // Add some space between ":" and the value
  },
  bold: {
    fontWeight: 'bold',
  },
}));

export default SalesHeadApprovalModal;
